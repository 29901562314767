import React, { useState, useEffect } from 'react';
import moment, { HTML5_FMT } from 'moment';
import {
	TABLE_PAGING_LENGTH,
	TIME_SERIES_REPEAT_TYPE,
	PRODUCT_TYPE,
	APP_FEP,
	TAXONOMY_TYPE,
	HR_RESOURCE_TYPE,
	OBJECT_TYPE,
	DATE_FORMAT_DISPLAY,
	TIME_FORMAT_DISPLAY,
	PARTICIPANT_STATUS,
} from '../../constants';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getPageProductAvailabilities, saveProductAvailability, removeProductAvailability } from './ProductServices';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { SplitButton } from 'primereact/splitbutton';
import { InputText } from 'primereact/inputtext';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { getTablePageReport } from '../../core/service/CommonService';
import { getListTaxons } from '../taxon/TaxonServices';
import { getListAssets } from '../asset/AssetServices';
import { Message } from 'primereact/message';
import Select from 'react-dropdown-select';
import { getPageSchedules, removeSchedule, saveSchedule } from '../object-schedule/ScheduleServices';
import { getListParticipants } from '../../constituent-management/participant/ParticipantServices';

export const AvailabilityScheduleView = (props) => {
	const availability = props.availability;
	const [frmVisible, setFrmVisible] = useState(false);
	const [frmData, setFrmData] = useState({});

	const [assetCategories, setAssetCategories] = useState([]);
	const [assets, setAssets] = useState([]);
	const [presenters, setPresenters] = useState(props.presenters ? props.presenters : []);

	const [errors, setErrors] = useState({});
	const [data, setData] = useState([]);
	const [isLoadTable, setLoadTable] = useState(false);
	const [filter, setFilter] = useState({});
	const [pageable, setPageable] = useState({
		page: 0,
		rows: 10,
		total: 0,
		sortField: 'startDate',
		sortOrder: -1,
	});

	useEffect(() => {
		loadAllAssetCategories();
	}, []);

	useEffect(() => {
		setPresenters(props.presenters ? props.presenters : []);
	}, [props.presenters]);

	useEffect(() => {
		if (props.availability) {
			setFilter({ objId: availability.id, objectType: OBJECT_TYPE.product_session, availableByCurrent: true });
			setLoadTable(true);
		} else setLoadTable(false);
	}, [props.availaibility]);

	useEffect(() => {
		if (isLoadTable && props.availability) {
			loadPageSchedules();
		}
	}, [isLoadTable]);

	useEffect(() => {
		if (frmData.assetCategoryId) {
			loadAllAssetByCategory(frmData.assetCategoryId);
		} else {
			setAssets([]);
		}
	}, [frmData.assetCategoryId]);

	const loadPageSchedules = () => {
		getPageSchedules(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder).then((res) => {
			if (res) {
				setData(res.content);
				setPageable({ ...pageable, total: res.totalElements, page: res.pageable.pageNumber, rows: res.pageable.pageSize });
				setLoadTable(false);
			}
		});
	};

	const onPage = (e) => {
		setPageable({
			...pageable,
			page: e.page,
		});
		setLoadTable(true);
	};

	const onSort = (e) => {
		setPageable({
			...pageable,
			sortField: e.sortField,
			sortOrder: e.sortOrder,
		});
		setLoadTable(true);
	};

	const onChangePageLength = (e) => {
		setPageable({ ...pageable, rows: e, page: 0 });
		setLoadTable(true);
	};

	const onFilterOnlyShowAvailableChange = (e) => {
		setFilter({ ...filter, availableByCurrent: e });
		setPageable({
			...pageable,
			page: 0,
		});

		setLoadTable(true);
	};

	const loadAllAssetCategories = () => {
		getListTaxons({ app: APP_FEP, type: TAXONOMY_TYPE.category.value, secondaryTypes: [TAXONOMY_TYPE.asset.value] }).then((res) => setAssetCategories(res));
	};

	const loadAllAssetByCategory = (categoryId) => {
		if (!categoryId) {
			setAssets([]);
			return;
		}

		let branchIds = props.branchId ? [props.branchId] : [];

		getListAssets({ app: APP_FEP, branchIds: branchIds, categoryId: categoryId }).then((res) => {
			setAssets(res);

			if (res && res.findIndex((a) => a.value === frmData.assignAssetId) === -1) setFrmData({ ...frmData, assignAssetId: null });
		});
	};

	const addOrEditItem = (e) => {
		setFrmData({
			id: e ? e.id : null,
			objId: availability ? availability.id : null,
			objType: availability ? OBJECT_TYPE.product_session : null,
			dateRange: e && e.startDate !== e.endDate ? true : false,
			startDate: e ? e.startDate : null,
			endDate: e ? e.endDate : null,
			startTime: e ? e.startTime : null,
			endTime: e ? e.endTime : null,
			startDateValue: e && e.startDate ? moment(e.startDate, HTML5_FMT.DATE).toDate() : null,
			endDateValue: e && e.endDate ? moment(e.endDate, HTML5_FMT.DATE).toDate() : null,
			startTimeValue: e && e.startTime ? moment(e.startTime, HTML5_FMT.TIME).toDate() : null,
			endTimeValue: e && e.endTime ? moment(e.endTime, HTML5_FMT.TIME).toDate() : null,
			assetCategoryId: e && e.assetCategoryId ? e.assetCategoryId : null,
			assignAssetId: e && e.assignAssetId ? e.assignAssetId : null,
			presenterIds: e && e.presenters ? e.presenters.map((p) => p.id) : [],
		});
		setFrmVisible(true);
	};

	const closeForm = () => {
		setFrmVisible(false);
		setFrmData({});
		setErrors({});
	};

	const deleteItem = async (id) => {
		showloading();

		let tmpParticipants = await getListParticipants({
			refIds: [availability.productId],
			productAvailabilityIds: [availability.id],
			statuses: [PARTICIPANT_STATUS.incomplete.value, PARTICIPANT_STATUS.pending.value, PARTICIPANT_STATUS.processing.value, PARTICIPANT_STATUS.completed.value],
		});

		if (tmpParticipants && tmpParticipants.length > 0) {
			showErrorNotify("This schedule's session already has participants, please remove participants first to perform this action");
			stoploading();
			return;
		}

		removeSchedule(id, availability.id, OBJECT_TYPE.product_session)
			.then((res) => {
				if (!res.errorCode) {
					setLoadTable(true);
					showSuccessNotify('Action submitted');
				} else {
					showErrorNotify(res.errorMessage);
				}
			})
			.finally(() => stoploading());
	};

	const submitSaveItem = () => {
		showloading();

		let tmpData = { ...frmData };
		tmpData.startDate = tmpData.startDateValue && moment(tmpData.startDateValue).isValid() ? moment(tmpData.startDateValue).format(HTML5_FMT.DATE) : '';
		tmpData.endDate = tmpData.endDateValue && moment(tmpData.endDateValue).isValid() ? moment(tmpData.endDateValue).format(HTML5_FMT.DATE) : '';
		tmpData.startTime = tmpData.startTimeValue && moment(tmpData.startTimeValue).isValid() ? moment(tmpData.startTimeValue).format(HTML5_FMT.TIME) : '';
		tmpData.endTime = tmpData.endTimeValue && moment(tmpData.endTimeValue).isValid() ? moment(tmpData.endTimeValue).format(HTML5_FMT.TIME) : '';

		saveSchedule(tmpData)
			.then((res) => {
				if (!res.errorCode) {
					showSuccessNotify('Action submitted!');
					setLoadTable(true);
					closeForm();
				} else {
					showErrorNotify(res.errorMessage);
					if (res.errorCode === 400) setErrors(res.errorObj);
				}
			})
			.finally(stoploading);
	};

	const onDateChange = (e, key) => {
		switch (key) {
			case 'start':
				setFrmData({ ...frmData, startDateValue: e });
				break;
			case 'end':
				setFrmData({ ...frmData, endDateValue: e });
				break;
			default:
				break;
		}
	};

	const onDateBlur = (e, key) => {
		switch (key) {
			case 'start':
				if (!frmData.startDateValue || !moment(frmData.startDateValue).isValid()) setFrmData({ ...frmData, startDateValue: null });
				break;
			case 'end':
				if (!frmData.endDateValue || !moment(frmData.endDateValue).isValid()) setFrmData({ ...frmData, endDateValue: null });
				break;
			default:
				break;
		}
	};

	const onTimeChange = (e, key) => {
		switch (key) {
			case 'start':
				setFrmData({ ...frmData, startTimeValue: e });
				break;
			case 'end':
				setFrmData({ ...frmData, endTimeValue: e });
				break;
			default:
				break;
		}
	};

	const onTimeBlur = (key) => {
		switch (key) {
			case 'start':
				if (!frmData.startTimeValue || !moment(frmData.startTimeValue).isValid()) setFrmData({ ...frmData, startTimeValue: null });
				break;
			case 'end':
				if (!frmData.endTimeValue || !moment(frmData.endTimeValue).isValid()) setFrmData({ ...frmData, endTimeValue: null });
				break;
			default:
				break;
		}
	};

	const onChangepresenters = (values) => {
		let arrValues = [];
		if (values) arrValues = values.map((e) => e.value);

		setFrmData({ ...frmData, presenterIds: arrValues });
	};

	const tblHeader = <TableHeader title='Schedules' pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)} changePageLength={(e) => onChangePageLength(e)} showRefresh={false} />;

	return availability ? (
		<React.Fragment>
			<div className='p-grid'>
				<div className='p-col-12 p-d-flex p-ai-center p-jc-between'>
					<h2 className='p-m-0'>{availability.name}'s Schedule </h2>
					<Button icon='pi pi-chevron-left' label='Back to sessions' className='p-button-warning btn-text-sm' onClick={() => props.goBack()} />
				</div>
			</div>

			<div className='p-hr p-mb-3'></div>

			<div className='p-grid'>
				{!frmVisible && (
					<div className='p-col-12'>
						<Button label='Add schedule' icon='pi pi-plus' className='p-button-success btn-text-sm' iconPos='left' onClick={() => addOrEditItem()} />
					</div>
				)}
				{frmVisible && (
					<div className='p-col-12 p-pb-0'>
						<Fieldset legend='Schedule setup'>
							<div className='p-grid'>
								{/* <div className="p-col-12">
                                <Checkbox inputId="cbIsRange" checked={frmData.dateRange} onChange={(e) => setFrmData({...frmData, dateRange: e.checked})} />
                                <label htmlFor="cbIsRange" className="p-margin-left-10">Is date range?</label>
                            </div> */}
								<div className='p-col-12 p-pb-0'>
									<div className='p-grid p-fluid form-group'>
										<div className='p-col-6 p-md-4'>
											<label className='p-label'>* {frmData.dateRange ? 'Start Date' : 'Date'}</label>
											<MaskedCalendar
												value={frmData.startDateValue}
												showIcon={true}
												onChange={(e) => onDateChange(e.value, 'start')}
												onBlur={(e) => onDateBlur(e.target.value, 'start')}
												dateFormat='dd-mm-yy'
											/>
											<span className='p-form-error'>{errors.startDate}</span>
										</div>
										{frmData.dateRange && (
											<div className='p-col-6 p-md-4'>
												<label className='p-label'>* End Date</label>
												<MaskedCalendar
													value={frmData.endDateValue}
													showIcon={true}
													onChange={(e) => onDateChange(e.value, 'end')}
													onBlur={(e) => onDateBlur(e.target.value, 'end')}
													dateFormat='dd-mm-yy'
												/>
												<span className='p-form-error'>{errors.endDate}</span>
											</div>
										)}
									</div>
								</div>
								<div className='p-col-12 p-pb-0'>
									<div className='p-grid p-fluid form-group'>
										<div className='p-col-6 p-md-4'>
											<label className='p-label'>* Start Time</label>
											<MaskedCalendar
												value={frmData.startTimeValue}
												showIcon={true}
												onChange={(e) => onTimeChange(e.value, 'start')}
												onBlur={(e) => onTimeBlur(e.target.value, 'start')}
												hourFormat='12'
												timeOnly={true}
											/>
											<span className='p-form-error'>{errors.startTime}</span>
										</div>
										<div className='p-col-6 p-md-4'>
											<label className='p-label'>* End Time</label>
											<MaskedCalendar
												value={frmData.endTimeValue}
												showIcon={true}
												onChange={(e) => onTimeChange(e.value, 'end')}
												onBlur={(e) => onTimeBlur(e.target.value, 'end')}
												hourFormat='12'
												timeOnly={true}
											/>
											<span className='p-form-error'>{errors.endTime}</span>
										</div>
									</div>
								</div>
								<div className='p-col-12 p-pb-0'>
									<div className='p-grid p-fluid form-group'>
										<div className='p-col-12 p-md-8'>
											<label className='p-label'>* Trainers</label>
											<Select
												multi
												values={presenters && frmData.presenterIds && frmData.presenterIds.length > 0 ? presenters.filter((x1) => frmData.presenterIds.some((x2) => x2 === x1.value)) : []}
												options={presenters}
												onChange={(values) => onChangepresenters(values)}
												style={{ width: '100%' }}
												placeholder='Select trainer'
												noDataRenderer={() => {
													return <span className='p-c p-padding-10-0'>No Data</span>;
												}}
											/>
											<div className='p-form-error'>{errors.presenters}</div>
										</div>
									</div>
								</div>
								<div className='p-col-12 p-pb-0'>
									<div className='p-grid p-fluid form-group'>
										<div className='p-col-6 p-md-4'>
											<label className='p-label'>Facility Category</label>
											<Dropdown
												value={frmData.assetCategoryId}
												options={assetCategories}
												showClear={true}
												onChange={(e) => setFrmData({ ...frmData, assetCategoryId: e.value })}
												placeholder='Select a facility category'
											/>
										</div>
										<div className='p-col-6 p-md-4'>
											<label className='p-label'>Facility Assign</label>
											<Dropdown
												value={frmData.assignAssetId}
												options={assets}
												showClear={true}
												showIcon={true}
												onChange={(e) => setFrmData({ ...frmData, assignAssetId: e.value })}
												placeholder='Select facility to assign'
											/>
										</div>
										{errors.existScheduled && (
											<div className='p-col-12 p-md-8'>
												<Message severity='error' text={errors.existScheduled} />
											</div>
										)}
									</div>
								</div>
							</div>
							<div className='p-grid p-mt-3'>
								<div className='p-col-12'>
									<Button label='Submit' className='btn-text-sm p-button-success' onClick={() => submitSaveItem()} />
									<Button label='Cancel' className='btn-text-sm p-button-default' onClick={() => closeForm()} />
								</div>
							</div>
						</Fieldset>
					</div>
				)}

				<div className='p-col-12 p-mt-5'>
					<Checkbox inputId='cbShowOnlyAvailable' onChange={(e) => onFilterOnlyShowAvailableChange(e.checked)} checked={filter.availableByCurrent}></Checkbox>
					<label htmlFor='cbShowOnlyAvailable' className='p-checkbox-label'>
						Only show available sessions?
					</label>
				</div>

				<div className='p-col-12'>
					<DataTable
						lazy={true}
						paginator={true}
						value={data}
						loading={isLoadTable}
						first={pageable.page * pageable.rows}
						header={tblHeader}
						onPage={onPage}
						onSort={onSort}
						rows={pageable.rows}
						totalRecords={pageable.total}
						sortField={pageable.sortField}
						sortOrder={pageable.sortOrder}
						responsive={true}
						emptyMessage='No content available!'
					>
						<Column
							header='Date'
							field='startDate'
							sortable={true}
							body={(rowData) => moment(rowData.startDate).format(DATE_FORMAT_DISPLAY) + (rowData.startDate !== rowData.endDate ? ' - ' + moment(rowData.endDate).format(DATE_FORMAT_DISPLAY) : '')}
						/>
						<Column header='Start Time' field='startTime' sortable={true} body={(rowData) => moment(rowData.startTime, HTML5_FMT.TIME).format(TIME_FORMAT_DISPLAY)} />
						<Column header='End Time' field='endTime' sortable={true} body={(rowData) => moment(rowData.endTime, HTML5_FMT.TIME).format(TIME_FORMAT_DISPLAY)} />
						<Column
							header='Trainer'
							body={(rowData) => {
								if (rowData.presenters) return rowData.presenters.map((p) => p.name).join(', ');
							}}
						/>
						<Column header='Assign Facility' field='assignAssetName' />
						<Column
							header=''
							className='tc-actions'
							style={{ textAlign: 'center', width: '15%' }}
							body={(rowData) => {
								const actionItems = [{ label: 'Remove', icon: 'pi pi-times', command: (e) => deleteItem(rowData.id) }];
								return <SplitButton label='Edit' model={actionItems} icon='pi pi-pencil' className='p-button-constrast' onClick={() => addOrEditItem(rowData)}></SplitButton>;
							}}
						/>
					</DataTable>
				</div>
			</div>
		</React.Fragment>
	) : (
		''
	);
};
